import React, { useState, useEffect } from 'react';
import { Card, CardContent } from './components/ui/card';
import { Alert, AlertDescription } from './components/ui/alert';
import { Button } from './components/ui/button';
import { Switch } from './components/ui/switch';
import { Analytics } from "@vercel/analytics/react"

// Import images using require.context
const importAll = (r) => {
  let images = {};
  r.keys().map((item) => { images[item.replace('./', '').replace('.png', '')] = r(item); });
  return images;
}

const regularLogos = importAll(require.context('./assets/logos/regular', false, /\.(png|jpe?g|svg)$/));
const funLogos = importAll(require.context('./assets/logos/fun', false, /\.(png|jpe?g|svg)$/));

const NFL_TEAMS = {
  ARI: { name: 'Arizona Cardinals' },
  ATL: { name: 'Atlanta Falcons' },
  BAL: { name: 'Baltimore Ravens' },
  BUF: { name: 'Buffalo Bills' },
  CAR: { name: 'Carolina Panthers' },
  CHI: { name: 'Chicago Bears' },
  CIN: { name: 'Cincinnati Bengals' },
  CLE: { name: 'Cleveland Browns' },
  DAL: { name: 'Dallas Cowboys' },
  DEN: { name: 'Denver Broncos' },
  DET: { name: 'Detroit Lions' },
  GB: { name: 'Green Bay Packers' },
  HOU: { name: 'Houston Texans' },
  IND: { name: 'Indianapolis Colts' },
  JAX: { name: 'Jacksonville Jaguars' },
  KC: { name: 'Kansas City Chiefs' },
  LV: { name: 'Las Vegas Raiders' },
  LAC: { name: 'Los Angeles Chargers' },
  LAR: { name: 'Los Angeles Rams' },
  MIA: { name: 'Miami Dolphins' },
  MIN: { name: 'Minnesota Vikings' },
  NE: { name: 'New England Patriots' },
  NO: { name: 'New Orleans Saints' },
  NYG: { name: 'New York Giants' },
  NYJ: { name: 'New York Jets' },
  PHI: { name: 'Philadelphia Eagles' },
  PIT: { name: 'Pittsburgh Steelers' },
  SF: { name: 'San Francisco 49ers' },
  SEA: { name: 'Seattle Seahawks' },
  TB: { name: 'Tampa Bay Buccaneers' },
  TEN: { name: 'Tennessee Titans' },
  WAS: { name: 'Washington Commanders' },
};
const fetchMatchups = async () => {
  const url = `https://site.api.espn.com/apis/site/v2/sports/football/nfl/scoreboard`;

  try {
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const data = await response.json();
    return data.events.map(event => ({
      id: event.id,
      home: event.competitions[0].competitors.find(team => team.homeAway === 'home').team.abbreviation,
      away: event.competitions[0].competitors.find(team => team.homeAway === 'away').team.abbreviation,
      startTime: new Date(event.date),
      status: event.status.type.state
    }));
  } catch (error) {
    console.error('Error fetching matchups:', error);
    throw error;
  }
};

const TeamCard = ({ team, isSelected, onClick, isNotPre, isFunMode }) => {
  const teamInfo = NFL_TEAMS[team] || { name: team };
  const logo = isFunMode ? funLogos[team] : regularLogos[team];

  return (
    <Card 
      className={`w-36 h-48 flex flex-col items-center justify-center cursor-pointer 
        ${isSelected ? 'bg-blue-200' : ''} 
        ${isNotPre ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer'}`}
      onClick={isNotPre ? undefined : onClick}
    >
      <CardContent className="p-3 flex flex-col items-center justify-center h-full">
        <div className="flex-grow flex items-center justify-center w-full">
          {logo ? (
            <img src={logo} alt={teamInfo.name} className="max-w-full max-h-24 object-contain" />
          ) : (
            <div className="w-24 h-24 bg-gray-200 flex items-center justify-center rounded-full">
              <span className="text-2xl font-bold">{team}</span>
            </div>
          )}
        </div>
        <p className="text-sm text-center font-semibold mt-2">{teamInfo.name}</p>
      </CardContent>
    </Card>
  );
};



const NFLMatchupSelector = () => {
  const [matchups, setMatchups] = useState([]);
  const [selections, setSelections] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isFunMode, setIsFunMode] = useState(false);

  useEffect(() => {
    const getMatchups = async () => {
      try {
        setLoading(true);
        const fetchedMatchups = await fetchMatchups();
        setMatchups(fetchedMatchups);
      } catch (err) {
        setError('Failed to fetch matchups. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    getMatchups();
  }, []);

  const handleSelection = (matchupId, team) => {
    const matchup = matchups.find(m => m.id === matchupId);
    if (matchup && matchup.status === 'pre') {
      setSelections(prev => ({
        ...prev,
        [matchupId]: team
      }));
    }
  };

  const clearSelections = () => {
    setSelections({});
  };

  const toggleFunMode = () => {
    setIsFunMode(!isFunMode);
  };

  const groupMatchupsByStartTime = (matchups) => {
    const groups = matchups.reduce((acc, matchup) => {
      const timeKey = matchup.startTime.toLocaleString('en-US', { 
        weekday: 'short', 
        month: 'short', 
        day: 'numeric',
        hour: 'numeric',
        minute: '2-digit',
        timeZone: 'America/New_York'
      });
      if (!acc[timeKey]) {
        acc[timeKey] = [];
      }
      acc[timeKey].push(matchup);
      return acc;
    }, {});

    return Object.entries(groups)
      .sort(([timeA], [timeB]) => {
        const dateA = new Date(timeA).getTime();
        const dateB = new Date(timeB).getTime();
        return dateA - dateB;
      })
      .map(([time, games]) => ({ time, games }));
  };

  if (loading) {
    return <div className="text-center mt-8">Loading matchups...</div>;
  }

  if (error) {
    return <Alert variant="destructive"><AlertDescription>{error}</AlertDescription></Alert>;
  }

  const groupedMatchups = groupMatchupsByStartTime(matchups);

  return (
    <div className="p-4">
      <div className="flex flex-col items-center mb-6">
        <h1 className="text-2xl font-bold mb-4">NFL Matchups</h1>
        <div className="flex items-center space-x-2 bg-gray-100 p-2 rounded-full">
          <span className={`text-sm ${!isFunMode ? 'font-bold' : ''}`}>Regular Mode</span>
          <Switch 
            checked={isFunMode} 
            onCheckedChange={toggleFunMode}
            className="bg-blue-200 data-[state=checked]:bg-blue-500"
          />
          <span className={`text-sm ${isFunMode ? 'font-bold' : ''}`}>Fun Mode</span>
        </div>
      </div>
      {groupedMatchups && groupedMatchups.length > 0 ? (
        groupedMatchups.map(({ time, games }) => (
          <div key={time} className="mb-8">
            <h2 className="text-xl font-semibold mb-4">{time}</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
              {games.map((matchup) => (
                <div key={matchup.id} className="flex justify-center items-center space-x-6">
                  <TeamCard
                    team={matchup.away}
                    isSelected={selections[matchup.id] === matchup.away}
                    onClick={() => handleSelection(matchup.id, matchup.away)}
                    isNotPre={matchup.status !== 'pre'}
                    isFunMode={isFunMode}
                  />
                  <span className="text-2xl font-bold">@</span>
                  <TeamCard
                    team={matchup.home}
                    isSelected={selections[matchup.id] === matchup.home}
                    onClick={() => handleSelection(matchup.id, matchup.home)}
                    isNotPre={matchup.status !== 'pre'}
                    isFunMode={isFunMode}
                  />
                </div>
              ))}
            </div>
          </div>
        ))
      ) : (
        <p>No matchups available at this time.</p>
      )}
      <div className="mt-8">
        <h2 className="text-xl font-bold mb-2">Your Selections:</h2>
        {Object.keys(selections).length > 0 ? (
          <>
            <ul className="mb-4">
              {Object.entries(selections).map(([matchupId, team]) => {
                const matchup = matchups.find(m => m.id === matchupId);
                const teamInfo = NFL_TEAMS[team] || { name: team };
                return (
                  <li key={matchupId}>
                    {teamInfo.name} ({team}) - {matchup.away} @ {matchup.home}
                  </li>
                );
              })}
            </ul>
            <Button onClick={clearSelections} variant="outline">Clear Selections</Button>
          </>
        ) : (
          <p>No selections made yet.</p>
        )}
      </div>
    </div>
  );
};

export default NFLMatchupSelector;